import React from 'react'
import Logo from './assets/qclap-logo.svg'
import ApleStoreLogo from './assets/appstore.svg'
import GooglePlayLogo from './assets/googleplay.svg'
import FacebookLogo from './assets/facebook.svg'
import XLogo from './assets/Twitter-X.svg'
import InstagramLogo from './assets/instagram.svg'

function App () {
  return (
    <div className="flex flex-col min-w-[100vw] min-h-[100vh] w-full h-full justify-around items-center">
      <div className='flex flex-col justify-center items-center lg:mt-28'>
        <img src={Logo} alt='QClap' width={120} />
        <p className='logo text-custom-white md:text-[44px] text-[38px]'>QClap</p>
      </div>
      <div className='flex w-full px-1 justify-center'>
        <h1 className='text-custom-white md:text-[56px] text-[40px]'>Launching soon!</h1>
      </div>
      <div className='flex w-full lg:max-w-[500px] max-w-[400px]'>
        <p className='description-text text-description md:text-[18px] text-[15px]'>QClap allows for transfer of information between individuals in a fully customizable social experience.</p>
      </div>
      <div className='flex gap-4 justify-center items-center'>
        <img src={ApleStoreLogo} alt='QClap appstore' width={140} className='cursor-pointer' />
        <img src={GooglePlayLogo} alt='QClap google plat' width={140} className='cursor-pointer' />
      </div>
      <div className='flex flex-col'>
        <p className='contact-text text-description'>For Investment opportunities, please contact</p>
        <p className='email-text text-custom-green'><a href="mailto:investors@qclap.com" target='_blank' rel="noreferrer" >investors@qclap.com</a></p>
      </div>
      <div className='flex gap-3 md:pb-2'>
        <img src={FacebookLogo} alt='Facebook profile' width={32} className='cursor-pointer' />
        <img src={XLogo} alt='X profile' width={32} className='cursor-pointer' />
        <img src={InstagramLogo} alt='Instagram profile' width={32} className='cursor-pointer' />
      </div>
    </div>
  )
}

export default App
